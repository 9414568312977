import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import PostRow from "../components/PostRow/PostRow"
import NewsletterBox from "../components/NewsletterBox/NewsletterBox"

// Styles
import "../styles/vars.css"
import "../styles/main.css"
import "../styles/buttons.css"
// import '../styles/typography.css'

import styles from "./index.module.css"

const Blog = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDesc = data.site.siteMetadata.description
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`Keycloak Themes`}
        description={siteDesc}
        titleTemplate={false}
      />
      <main className={styles.main}>
        {posts.map(({ node }) => (
          <PostRow key={node.frontmatter.slug} post={node} />
        ))}
        <NewsletterBox />
      </main>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
      limit: 2000
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date
            tags
            excerpt
          }
        }
      }
    }
  }
`
